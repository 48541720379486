import type { StoryblokStory } from "storyblok-generate-ts"

export const storyKey = Symbol() as InjectionKey<StoryblokStory<any>>
export const isEditorKey = Symbol() as InjectionKey<boolean>
export const environmentKey = Symbol() as InjectionKey<"production" | "staging" | "development">

export const storyblokEnvKey = Symbol() as InjectionKey<"published" | "draft">

export const nextStepKey = Symbol() as InjectionKey<() => void>
export const prevStepKey = Symbol() as InjectionKey<() => void>
