import revive_payload_client_PVCB9WGSct from "/app/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.4_eslint@8.57.0_floating-vue@5.2.2_sass@1.72.0_typescript@5.4.3_unocss@0.58.4_vite@5.2.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jgdlXo0i3X from "/app/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.4_eslint@8.57.0_floating-vue@5.2.2_sass@1.72.0_typescript@5.4.3_unocss@0.58.4_vite@5.2.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xj8ea27iH7 from "/app/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.4_eslint@8.57.0_floating-vue@5.2.2_sass@1.72.0_typescript@5.4.3_unocss@0.58.4_vite@5.2.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_sB70PitsPU from "/app/node_modules/.pnpm/nuxt-site-config@2.2.11_@nuxt+devtools@1.0.8_@vue+compiler-core@3.4.21_nuxt@3.11.1_postcss@8._vntvnlvrkzudmb7kaqfc5vgloe/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_iUiO4knNdb from "/app/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.4_eslint@8.57.0_floating-vue@5.2.2_sass@1.72.0_typescript@5.4.3_unocss@0.58.4_vite@5.2.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_BYjY6sd7Ub from "/app/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.4_eslint@8.57.0_floating-vue@5.2.2_sass@1.72.0_typescript@5.4.3_unocss@0.58.4_vite@5.2.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_hjj64yPrnK from "/app/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.4_eslint@8.57.0_floating-vue@5.2.2_sass@1.72.0_typescript@5.4.3_unocss@0.58.4_vite@5.2.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_fUEvfpaGmZ from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import titles_iX35LsvtPW from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.9_@lezer+common@1.2.1_@nuxt+devtools@1.0.8_@unhead+shared@1.8.20_@vue+co_b77dxm5mgzl3l5lmifgjqjfnfy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_6SdrUdZQ5V from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.9_@lezer+common@1.2.1_@nuxt+devtools@1.0.8_@unhead+shared@1.8.20_@vue+co_b77dxm5mgzl3l5lmifgjqjfnfy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_3YSqarsCbV from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.0.8_@vue+compiler-core@3.4.21_nuxt@3.11.1_po_2qotykmk7xxrbrw2c4xm4jyaki/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_WrMBEI7veE from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.0.8_@vue+compiler-core@3.4.21_nuxt@3.11.1_po_2qotykmk7xxrbrw2c4xm4jyaki/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_46AqloBM3Y from "/app/node_modules/.pnpm/@storyblok+nuxt@6.0.6/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_hqONyENWKK from "/app/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.4_eslint@8.57.0_floating-vue@5.2.2_sass@1.72.0_typescript@5.4.3_unocss@0.58.4_vite@5.2.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_PVCB9WGSct,
  unhead_jgdlXo0i3X,
  router_xj8ea27iH7,
  _0_siteConfig_sB70PitsPU,
  payload_client_iUiO4knNdb,
  check_outdated_build_client_BYjY6sd7Ub,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hjj64yPrnK,
  plugin_fUEvfpaGmZ,
  titles_iX35LsvtPW,
  defaults_6SdrUdZQ5V,
  siteConfig_3YSqarsCbV,
  inferSeoMetaPlugin_WrMBEI7veE,
  plugin_46AqloBM3Y,
  chunk_reload_client_hqONyENWKK
]