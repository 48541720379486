import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAbTest, LazyAssetImage, LazyCardText, LazyCoachingPayButton, LazyCtaButton, LazyFormSecrets, LazyGrid, LazyHeadline, LazyHsForm, LazyHsMeeting, LazyLayout, LazyLinkListElement, LazyLinkList, LazyPage, LazyProductOverview, LazyProgressionStep, LazyProgression, LazyQuote, LazySpacer, LazySpinToWin, LazyStaticVideo, LazyStepList, LazyStepper, LazyStepsStep, LazyTeaser, LazyText, LazyTrackThankYou, LazyYoutube, LazyNuxtIcon } from '#components'
const lazyGlobalComponents = [
  ["AbTest", LazyAbTest],
["AssetImage", LazyAssetImage],
["CardText", LazyCardText],
["CoachingPayButton", LazyCoachingPayButton],
["CtaButton", LazyCtaButton],
["FormSecrets", LazyFormSecrets],
["Grid", LazyGrid],
["Headline", LazyHeadline],
["HsForm", LazyHsForm],
["HsMeeting", LazyHsMeeting],
["Layout", LazyLayout],
["LinkListElement", LazyLinkListElement],
["LinkList", LazyLinkList],
["Page", LazyPage],
["ProductOverview", LazyProductOverview],
["ProgressionStep", LazyProgressionStep],
["Progression", LazyProgression],
["Quote", LazyQuote],
["Spacer", LazySpacer],
["SpinToWin", LazySpinToWin],
["StaticVideo", LazyStaticVideo],
["StepList", LazyStepList],
["Stepper", LazyStepper],
["StepsStep", LazyStepsStep],
["Teaser", LazyTeaser],
["Text", LazyText],
["TrackThankYou", LazyTrackThankYou],
["Youtube", LazyYoutube],
["NuxtIcon", LazyNuxtIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
