import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as datenschutz8Idu2tRmArMeta } from "/app/pages/datenschutz.vue?macro=true";
import { default as impressumLDkFRkkSAmMeta } from "/app/pages/impressum.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93ILszah2VNpMeta?.name ?? "slug",
    path: _91_46_46_46slug_93ILszah2VNpMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    alias: _91_46_46_46slug_93ILszah2VNpMeta?.alias || [],
    redirect: _91_46_46_46slug_93ILszah2VNpMeta?.redirect,
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: datenschutz8Idu2tRmArMeta?.name ?? "datenschutz",
    path: datenschutz8Idu2tRmArMeta?.path ?? "/datenschutz",
    meta: datenschutz8Idu2tRmArMeta || {},
    alias: datenschutz8Idu2tRmArMeta?.alias || [],
    redirect: datenschutz8Idu2tRmArMeta?.redirect,
    component: () => import("/app/pages/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: impressumLDkFRkkSAmMeta?.name ?? "impressum",
    path: impressumLDkFRkkSAmMeta?.path ?? "/impressum",
    meta: impressumLDkFRkkSAmMeta || {},
    alias: impressumLDkFRkkSAmMeta?.alias || [],
    redirect: impressumLDkFRkkSAmMeta?.redirect,
    component: () => import("/app/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  }
]