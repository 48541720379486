import type { RichtextStoryblok } from "~/component-types-sb"

export type facebookCommonEvent = {
  AddPaymentInfo: facebook.Pixel.AddPaymentInfoParameters
  AddToCart: facebook.Pixel.AddToCartParameters
  AddToWishlist: facebook.Pixel.AddToWishlistParameters
  CompleteRegistration: facebook.Pixel.CompleteRegistrationParameters
  Contact: facebook.Pixel.LeadParameters
  CustomizeProduct: facebook.Pixel.CustomParameters
  Donate: facebook.Pixel.PurchaseParameters
  FindLocation: facebook.Pixel.ViewContentParameters
  InitiateCheckout: facebook.Pixel.InitiateCheckoutParameters
  Lead: facebook.Pixel.LeadParameters
  Purchase: facebook.Pixel.PurchaseParameters
  Schedule: facebook.Pixel.CustomParameters
  Search: facebook.Pixel.SearchParameters
  StartTrial: facebook.Pixel.CustomParameters
  SubmitApplication: facebook.Pixel.CustomParameters
  Subscribe: facebook.Pixel.CustomParameters
  ViewContent: facebook.Pixel.ViewContentParameters
}

export const hubspotTrack = (action: Hsq.EventNames, options?: unknown) => {
  // @ts-expect-error
  window?._hsq?.push([action, options])
}

export const metaTrack = (
  type: "track" | "trackCustom" | "consent",
  event: string,
  params: facebookCommonEvent[keyof facebookCommonEvent] | Record<string, string | number | string[] | number[]> = {}
) => {
  const eventID = new Date().getTime().toString()
  window?.fbq?.(type, event, params, { eventID })

  if (type !== "consent") $fetch("/api/t/meta", { body: { type, event, params, eventID }, method: "POST" })
}

export const googleTagTrack: Gtag.Gtag = (command, ...args) => {
  window?.gtag?.(command, ...args)
}

export const hubspotBanner = (action: Omit<Hsp.EventNames, "addPrivacyConsentListener">) => {
  window?._hsp?.push([action])
}

export function withConsent(action: (consent: Hsp.consentStatus) => void) {
  window?._hsp?.push(["addPrivacyConsentListener", action])
}

export function renderRTF(richtext?: RichtextStoryblok) {
  return computed(() => (typeof richtext === "string" ? richtext : renderRichText(richtext)))
}
