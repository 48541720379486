<template>
  <div class="min-h-dvh flex flex-col">
    <audoora-header class="relative z-30" />
    <main class="container mx-auto flex flex-col flex-grow gap-4 px-4 py-[min(12rem,20dvh)]" @dblclick="showStack = true">
      <h1 class="text-center text-4xl font-semibold">
        Fehler {{ error.statusCode }}
      </h1>
      <h2 class="text-center text-4xl">
        {{ error.statusMessage }}
      </h2>
      <br />
      <transition name="fade-bottom">
        <code v-show="showStack" class="overflow-x-auto whitespace-pre font-mono" v-html="error.stack" />
      </transition>
      <br />
      <nuxt-link to="/" class="button w-full tablet:max-w-96 mx-auto">
        Zurück zur Startseite
      </nuxt-link>
    </main>
    <audoora-footer class="relative z-30" />
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "nuxt/app"
import { environmentKey } from "./injection-symbols"

defineProps<{ error: NuxtError }>()

const environment = inject(environmentKey)
const showStack = ref(environment === "development")
</script>
