<template>
  <footer class="mt-auto px-8 pb-4 pt-12">
    <nav class="container mx-auto flex flex-row flex-wrap justify-start gap-x-8 gap-y-4 tablet:justify-center">
      <atoms-link-list row class="items-center text-center">
        <li>
          <nuxt-link to="/datenschutz">
            Datenschutz
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/impressum">
            Impressum
          </nuxt-link>
        </li>
        <li>
          <button
            id="hs_show_banner_button"
            class="-my-0.5 whitespace-nowrap text-center leading-none"
            type="button"
            @click="hubspotBanner('showBanner')"
          >
            Cookie-Einstellungen
          </button>
        </li>
      </atoms-link-list>
    </nav>
  </footer>
</template>
