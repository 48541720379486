<template>
  <NuxtPage />
</template>

<script setup lang="ts">
import { environmentKey } from "./injection-symbols"

useHead({
  link: [
    { rel: "icon", href: "/favicon.svg", type: "image/svg+xml" },
    { rel: "icon", href: "/favicon-dark.svg", type: "image/svg+xml", media: "(prefers-color-scheme:dark)" },
    { rel: "icon", href: "/favicon.png", type: "image/png" },
    { rel: "icon", href: "/favicon-dark.png", type: "image/png", media: "(prefers-color-scheme:dark)" },
  ],
  meta: [{ name: "google-site-verification", content: "eSP2xf92wuLfTTMFd1NumfbxqbodAxS_limwUj2YE4U" }],
})

useSeoMeta({
  colorScheme: "light dark",
  ogType: "website",
  themeColor: [
    { content: "#08213D", media: "(prefers-color-scheme: dark)" },
    { content: "#CEF2E0", media: "(prefers-color-scheme: light)" },
  ],
  twitterCard: "summary_large_image",
})

provide(
  environmentKey,
  (useRuntimeConfig().public.siteEnv as "production" | "staging" | "development") ||
    (process.env.NODE_ENV as "production" | "development"),
)
</script>

<style lang="scss">
:is(.page-enter-active, .page-leave-active, .page-reverse-enter-active, .page-reverse-leave-active) {
  @apply duration-150;

  main {
    @apply transition-[transform,opacity];
  }
}

:is(.page-enter-from, .page-leave-to, .page-reverse-enter-from, .page-reverse-leave-to) main {
  @apply translate-x-0 opacity-0;
}

:is(.page-leave-to, .page-reverse-enter-from) main {
  @apply -translate-x-4;
}

:is(.page-enter-from, .page-reverse-leave-to) main {
  @apply translate-x-4;
}
</style>
